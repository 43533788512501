<template>
  <v-container
    fill-height
    fluid
    :class="`login-screen${isDesktop ? '-desktop' : ''} d-flex`"
  >
    <div :class="isDesktop ? 'desktop-container' : 'mobile-container'">
      <v-container class="content-area">
        <div class="oval1"></div>
        <div class="oval2"></div>
        <v-row class="align-start">
          <v-col class="pa-0 app-logo--desktop app-logo"></v-col>
        </v-row>
        <v-row>
          <p class="desktop-tb-text">
            Zentek Connect bietet eine <br />
            einfache und digitale Lösung für<br />
            schnelles Abfallmanagement.
          </p>
        </v-row>
        <v-row class="bottom">
          <div class="mx-auto mobile footer-links">
            <router-link :to="{ name: 'Privacy' }">Datenschutz</router-link>
            <router-link :to="{ name: 'Imprint' }" style>Impressum</router-link>
            <br /><br />
            <div style="color: #fff">
              powered by Zentek<sup
                ><v-icon
                  class="trademark"
                  style="font-size: 0.5rem; margin-right: 5px"
                  >mdi-registered-trademark</v-icon
                ></sup
              >
            </div>
          </div>
        </v-row>
      </v-container>
      <v-container class="pa-0 text-center desktop-form-area" v-if="isDesktop">
        <v-row>
          <v-col cols="12" class="form-container col-login">
            <h2 class="desktop-form-area--heading">Anmeldung</h2>
            <login-form
              class="form-btn"
              v-if="showLogin"
              :dark="false"
              :filled="true"
              elevation="2"
            />
            <password-request v-if="!showLogin" :dark="darkswitch" />
            <v-btn
              v-if="!hasInjectedAuthentication"
              text
              @click="showLogin = !showLogin"
              class="mt-5 pw-forgot info-text"
              data-cy="forgot-password-button"
              >{{ forgotButtonLabel }}</v-btn
            >
            <br />
            <br />
<!--             <p class="register">
              Noch nicht registriert? <br />
              <a :href="'https://www.zentek.de/gewerbliche-entsorgung/'">Hier</a> erhalten Sie
              mehr Informationen
            </p> -->
            <!-- above code block is not deleted since we're probably gonna find some other wording or link for it in the future -->
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mobile-form-area" v-else>
        <v-row class="mobile-login">
          <v-col class="text-center pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="10" sm="6" md="6" class="pb-0 mx-auto fancy-form">
                  <login-form v-if="showLogin" :dark="true" :filled="false" />
                  <password-request v-if="!showLogin" :dark="darkswitch" />
                  <v-btn
                    v-if="!hasInjectedAuthentication"
                    text
                    @click="showLogin = !showLogin"
                    class="mt-5 info--text"
                    >{{ forgotButtonLabel }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import LoginForm from "@/components/authentication/LoginForm";
import PasswordRequest from "@/components/authentication/PasswordRequest";
import { mapState } from "vuex";

export default {
  name: "Login",
  props: ["dark"],
  components: {
    LoginForm,
    PasswordRequest,
  },
  data: () => {
    return {
      showLogin: true,
    };
  },
  computed: {
    forgotButtonLabel() {
      return this.showLogin ? "Passwort vergessen?" : "Zum Login";
    },
    ...mapState({
      hasInjectedAuthentication: (state) =>
        state.user.externalAppConfig.email !== undefined,
    }),
    darkswitch() {
      if (this.$vuetify.breakpoint.width > 800) {
        return false;
      }
      return true;
    },
    isDesktop() {
      if (this.$vuetify.breakpoint.width > 800) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
//General CSS
$light-blue: #b3deeb;
.rn-Login {
  .login-screen {
    background: $app-highlight;
    position: relative;
  }
  header.v-toolbar {
    background: #52ded9 !important;
  }
}
.v-application--is-ltr {
  .v-text-field {
    .v-input__prepend-inner {
      padding: 0 10px;
    }
  }
}
.trademark.v-icon {
  color: #fff !important;
}

//Desktop CSS
.desktop-container {
  width: 800px;
  height: 650px;
  position: relative;
  margin: 0 auto;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-box-shadow: $standard-webkit-shadow;
  -moz-box-shadow: $standard-moz-shadow;
  box-shadow: $standard-shadow;
}
.content-area {
  padding: 0;
  background: $app-highlight;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 44%;
  border-radius: 30px 0 0 30px;
  @media (min-width: 800px) {
    overflow: hidden;
    position: relative;
  }

  .oval1 {
    position: absolute;
    width: 404px;
    height: 404px;
    top: -139px;
    left: 26%;
    background: radial-gradient(
      circle at center,
      $app-highlight-2 0,
      transparent 70%
    );
    opacity: 0.7;
    //transform: scaleX(1.5) rotate(45deg);
    z-index: 1;
  }
  .oval2 {
    width: 876px;
    height: 876px;
    top: -187px;
    left: -39%;
    background: radial-gradient(
      circle at center,
      $app-highlight-2 0,
      transparent 70%
    );
    opacity: 0.7;
    position: absolute;

    & ~ div {
      z-index: 2;
      position: relative;
    }
  }
}
.row.bottom {
  @media (max-width: 800px) {
    position: absolute !important;
    bottom: 0;
    width: 100%;
  }
}
.login-screen-desktop {
  max-width: 100vw !important;
  background-color: #f2f1f7;
  min-height: 100vh;
}
.desktop-tb-text {
  line-height: 2.8em;
  letter-spacing: 0.07em;
  font-size: 0.875em;
  font-style: italic;
  text-align: center;
  color: #fff;
  margin: 60px auto 0 auto;
}
.app-logo--desktop {
  background: url("../assets/LogoWithText.svg") no-repeat center;
  min-height: 135px;
  margin-top: 80px !important;
}
.desktop-form-area {
  background-color: #fff;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  width: 56%;
  border-radius: 0 30px 30px 0;
  .desktop-form-area--heading {
    margin: 80px 0 30px 0;
    font-size: 2em;
  }
}

.mobile-form-area {
  position: relative;
  top: calc(43vh - 200px);
  z-index: 3;
  .col,
  .container {
    padding: 0;
  }

  &:deep(.v-text-field__details) {
    margin: 0 !important;
  }

  &:deep(.v-input) {
    padding: 0;
  }
}
.footer-links {
  margin-top: 125px;
  a {
    padding: 0 10px;
    color: white;
    text-decoration: underline;
  }
}
.form-container {
  margin: 0 auto;
}
.v-btn.pw-forgot {
  color: $app-medium-grey !important;
  caret-color: $app-medium-grey !important;
}
.register {
  color: $app-medium-grey !important;
  caret-color: $app-medium-grey !important;
  font-size: small;
}
.form-btn {
  padding: 0 25px;
}
//Mobile CSS
@media (max-width: 800px) {
  .mobile-container {
    margin-top: 20%;
    width: 100%;
    min-height: 80%;
    .v-input {
      input {
        background-color: transparent !important;
      }
      .v-text-field__slot {
        background-color: transparent !important;
      }
    }
    .v-input__icon--prepend-inner {
      .mdi {
        color: #fff !important;
        -webkit-appearance: none;
      }
    }
    .v-input__append-inner {
      .mdi {
        color: #fff !important;
        -webkit-appearance: none;
      }
    }
    .v-icon {
      color: #fff !important;
      -webkit-appearance: none;
    }
  }
  .desktop-tb-text {
    visibility: hidden;
  }
  .desktop-form-area {
    visibility: hidden;
  }
  .content-area {
    width: 100%;
    padding-bottom: 15px;
    height: 100vh;
    border-radius: 0;
  }
  .app-logo {
    background: url("../assets/LogoWithText.svg") no-repeat center;
    height: auto;
    width: 70%;
    margin-top: 40px !important;
  }
  .footer-links {
    text-align: center;
    font-size: small;
    position: absolute;
    width: 100%;
    bottom: 50px;
    left: 0;
    a {
      position: relative;
      letter-spacing: 0.05em;
      color: white !important;
      text-decoration: underline;
      margin: 0 auto;
    }
  }
  .v-application {
    .info--text {
      color: white !important;
      caret-color: $light-blue !important;
    }
  }
  .col-login {
    min-width: 50%;
    max-width: 100%;
  }
  .mobile-login {
    margin-top: 50px;
  }
}
</style>
